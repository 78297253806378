<template>
  <div>
    <div class="title"> <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>我的审批</div>
     <el-card class="box-card " style="margin-top: 10px;">
       <el-row class="but">
            <el-button type="primary"  @click="change(10)" :class="{ newStyle: 10 === number }" plain>执行中</el-button>
            <el-button type="primary"  @click="change(-1)" :class="{ newStyle: -1 === number }" plain >全部</el-button>
            <el-button type="primary"  @click="change(-2)" :class="{ newStyle: -2 === number }" plain >已完成</el-button>
        </el-row>
         <el-row class="but" v-if="number==10" style="margin-top:10px">
             <el-button type="primary"  @click="change1(1)" :class="{ newStyle: 1 === number1 }" plain>执行中</el-button>
            <el-button type="primary"  @click="change1(2)" :class="{ newStyle: 2 === number1 }" plain >全部</el-button>
        </el-row>
      <el-table
       v-loading="load"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 10px">
      <el-table-column
        header-align="center"
        align="center"
        prop="configName"
        label="审批流名称"
        >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="no"
        label="流程编号"
        >
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="stateText"
        width="130"
        label="流程状态">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="createdAt"
        label="提交时间">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="curApprovers"
        label="当前审批人">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="oaStateText"
        label="审批结果">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="finishTime"
        label="完成时间">
      </el-table-column>
     <el-table-column
       header-align="center"
        align="center"
        label="操作">
        <template slot-scope="scope">
         <el-button  type="text" size="small"  @click="seeDetails(scope.row.taskId)">查看明细</el-button>
        </template>
      </el-table-column>
      </el-table>
     <el-pagination
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
     ></el-pagination>    
    </el-card>
  </div>
</template>

<script>
import {oaWorkbench,taskDetail} from  "@/api/workbench.js";
export default {
  data() {
    return {
        load:false,
        activeName: 'first',
        activeName1: 'first',
        tableData: [],
        pageSize: 10,
        totalCount: 0,//数据长度
        currPage: 1,
        taskState:10,
        approveType:1,//
        number:10,
        number1:1,
    };
  },
  mounted(){
    //   this.getDataList()
 },
  methods: {
     change(index){
      this.number = index
      this.getDataList()
    },
     change1(index){
      this.number1 = index
      this.getDataList()
    },
   getDataList(){
     console.log(this.taskState,"this.taskState");
     this.load=true,
     oaWorkbench({
       "approveType":this.number1,
       "myType":3,
       "taskType": this.number,
       "current": this.currPage,
        "size": this.pageSize,
     }).then((data=>{
       this.load=false
            if (data&&data.code==200) {
            this.tableData=data.data.records
            this.totalCount=data.data.total
           }
     }))
  },

   // 每也数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.currPage = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.currPage = val;
        this.getDataList();
      },
      handleClick(tab, event) {
       this.currPage=1
       this.pageSize=10
        if (tab.index==0)  this.taskState=10
        if (tab.index==1)  this.taskState=-1
        if (tab.index==2)  this.taskState=-2
          this.getDataList() 
      },
      handleClick1(tab){
        this.currPage=1
       this.pageSize=10
        if (tab.index==0)  this.approveType=1
        if (tab.index==1)  this.approveType=2
          this.getDataList() 
        
      },
        // 查看详情
      seeDetails(a){
        this.$router.push({name:"/workbench/details",query:{Id:a}})
      },
    }
};
</script>

<style  scoped>
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
     color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
}
.main{
    margin-top: 10px;
}
.but .buton{
  width: 80px;
  margin-left: 0px;
  /* border-radius: 0px; */
}
.but button{
  width: 80px;
  margin-left: 0px;
  border-radius: 0px;
}
div /deep/ .newStyle {
    background-color: #9e99b9;
    color: #fff;
  }
</style>


